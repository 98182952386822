import React, { useState, useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";

const Payment = (props) => {
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const totalPrice = props.TotalPrice * 0.25;

  const userDetails = (name, surname, email, phone) => {
    props.details(name, surname, email, phone);
  };

  useEffect(() => {
    
    async function fetchData() {
      
      try {
       
        // Fetch publishableKey from GCP function
        const response = await fetch(
          "https://us-central1-solar-pv-sales.cloudfunctions.net/GEHCO-Stripe-Payment-Config"
        );
        const { publishableKey } = await response.json();
        setStripePromise(loadStripe(publishableKey));
      } catch (error) {
        // Handle any errors that might occur
        console.error("Error:", error);
      }
      if(props.product!=undefined){
        // console.log("25% of Total Price====>",totalPrice);
      // console.log("Products========>", props.product);
  try {
    const token = process.env.REACT_APP_PAYMENT_TOKEN
    // console.log("Toekn======>1",totalPrice);
  // Send totalPrice to GCP function using Axios POST request
  const { data } = await axios.post(
    "https://gehco-stripe-5b5b4117f567.herokuapp.com/payment-intent",
     { totalPrice: totalPrice },{ headers: { Authorization: `Bearer ${token}` } }
    );
    const { clientSecret } = data;
    // console.log("Responssseeee",data);
    // Handle the clientSecret value
    setClientSecret(clientSecret);
  }catch (error) {
    // Handle any errors that might occur
    console.error("Error:", error);
  }
}
     
    }
    fetchData();
  }, [totalPrice, props.product]);

 
  // console.log("Total Price====>", props.TotalPrice);
  // console.log("Products========>", props.product);

  return (
    <>
      {clientSecret && stripePromise && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <CheckoutForm
            totalPrice={props.totalPrice}
            userDetails={props.details}
            Order={props.product}
            Address={props.address}
            Total25={totalPrice}
          />
        </Elements>
      )}
    </>
  );
};

export default Payment;
