import React, { Suspense,useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Payment from '../src/components/sections/hometwo/Payment'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import $ from 'jquery';
import Calendly from '../src/components/sections/hometwo/Calendly' 



// 
// Preloader
const Preloader = React.lazy(() => import("./components/layouts/Preloader"));

// Pages
const Home = React.lazy(() => import("./components/pages/Home"));
const About = React.lazy(() => import("./components/pages/About"));
const Blogstandard = React.lazy(() => import("./components/pages/Blogstandard"));
const Bloggrid = React.lazy(() => import("./components/pages/Bloggrid"));
const Blogdetails = React.lazy(() => import("./components/pages/Blogdetails"));
const Portfoliogrid = React.lazy(() => import("./components/pages/Portfoliogrid"));
const Portfolioslider = React.lazy(() => import("./components/pages/Portfolioslider"));
const Portfoliomasonary = React.lazy(() => import("./components/pages/Portfoliomasonary"));
const Portfoliodetails = React.lazy(() => import("./components/pages/Portfoliodetails"));
const Service = React.lazy(() => import("./components/pages/Service"));
const Servicedetails = React.lazy(() => import("./components/pages/Servicedetails"));
const Team = React.lazy(() => import("./components/pages/Team"));
const Teamdetails = React.lazy(() => import("./components/pages/Teamdetails"));
const Whyus = React.lazy(() => import("./components/pages/Whyus"));
const Faqs = React.lazy(() => import("./components/pages/Faqs"));
const Contact = React.lazy(() => import("./components/pages/Contact"));
const Error = React.lazy(() => import("./components/pages/Error"));
const Chatbot = React.lazy(() => import("./components/pages/Chatbot"));
const Completion = React.lazy(() => import("./components/pages/Completion"));


function App() {
  return (
  
    <Router >
      <Suspense fallback={<div></div>}>
        
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/chatbot" component={Chatbot} />
          <Route exact path="/completion" component={Completion} />
          <Route exact path="/about" component={About} />
          <Route exact path="/blog-standard" component={Blogstandard} />
          <Route exact path="/blog-grid" component={Bloggrid} />
          <Route exact path="/blog-details" component={Blogdetails} />
          <Route exact path="/portfolio-grid" component={Portfoliogrid} />
          <Route exact path="/portfolio-slider" component={Portfolioslider} />
          <Route exact path="/portfolio-masonary" component={Portfoliomasonary} />
          <Route exact path="/portfolio-details" component={Portfoliodetails} />
          <Route exact path="/services" component={Service} />
          <Route exact path="/service-details" component={Servicedetails} />
          <Route exact path="/team" component={Team} />
          <Route exact path="/team-details" component={Teamdetails} />
          <Route exact path="/why-us" component={Whyus} />
          <Route exact path="/faq" component={Faqs} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/error-page" component={Error} />
          <Route exact component={Error} />
        </Switch>
      </Suspense>
    </Router>
  );
}

export default App;
