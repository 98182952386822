import React from "react";
import { InlineWidget } from "react-calendly";

const Calendly = (props) => {
  // Define a pageSettings object to pass to the Calendly widget
  const logging=()=>{
    console.log("logging====>",props.userInfo)
  }
  logging()
  const pageSettings = {
    backgroundColor: 'ffffff',
  hideEventTypeDetails: false,
  hideLandingPageDetails: false,
  primaryColor: '00a2ff',
  textColor: '4d5055'
  };
  const prefill = {
    email: props.userInfo.email ? props.userInfo.email.value : '',
    firstName: props.userInfo.username ? props.userInfo.username.value : '',
    lastName: props.userInfo.surname ? props.userInfo.surname.value : '',
    name: `${props.userInfo.username ? props.userInfo.username.value : ''} ${props.userInfo.surname ? props.userInfo.surname.value : ''}`,
  };
 
  const completion = () => {
    // props.Next()
    // alert("Schedule completed")
  }
  return (
    <div className="App">
      <div>

      </div>
      <InlineWidget
        url="https://calendly.com/gehco/30min"
        pageSettings={pageSettings}
        prefill={prefill}
        styles={{ height: '890px', backgroundColor:'#BCC6CC', borderRadius: '20px' }}
        onEventScheduled={completion} // Add the onEventScheduled prop here
      />
    </div>
  );
};

export default Calendly;
