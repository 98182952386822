import { PaymentElement } from "@stripe/react-stripe-js";
import { useState,useEffect } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import './Card.css';
import axios from 'axios'


export default function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [prices,setPrices]=useState([])
  const [products,setProducts]=useState([])
  const [fileType,setFileType]=useState('')
  const [Errors,setErrors]=useState(false)

  useEffect(() => {
    if (props.userDetails && props.userDetails.username && props.userDetails.username.value && props.Order ) {
      
// console.log("PRoducts from CheckoutForm===>",props.Order);
      let products =[];
      let prices=[];
      let fileType;
      
      for(let i=0;i<props.Order.length;i++){
        
        products.push(props.Order[i].description)
        prices.push(props.Order[i].Price)
        if(props.Order[i].description.includes('Large system')){
          fileType= 'Large system'
        }
        if(props.Order[i].description.includes('Medium system')){
          fileType= 'Medium system'
        }
        if(props.Order[i].description.includes('Small system')){
          fileType= 'Small system'
        }
      }
      // console.log("Products Prices===>",products, prices);
      // console.log("Products address===>",props.Address);
      // console.log("fileType===>",fileType);

      setFileType(fileType)
      setProducts(products)
      setPrices(prices)
      setEmail(props.userDetails.email.value);
      setName(props.userDetails.username.value);
      setSurname(props.userDetails.surname.value);
      setPhone(props.userDetails.phone.value);
      setAddress(props.Address);
    }
  }, [props.userDetails, props.Order]);
  // console.log("props checkout===>",props.userDetails, props.Order);
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!stripe || !elements) {
      return;
    }

    if (!name || !surname || !email || !phone || !products || !prices) {
      setMessage("Please fill in all fields including User Details and Orders section.");
      return;
    }

    if (!/\S+@\S+\.\S+/.test(email)) {
      setMessage("Please enter a valid email address in the User Details section.");
      return;
    }

    if (!/^[0-9]{11}$/.test(phone)) {
      setMessage("Please enter a valid 11-digit phone number in the User Details section.");
      return;
    }
    
    setIsProcessing(true);

    
    //  if (error) {
    //   setMessage(error.message);
    //    console.log("Stripe MEssage====>",error.message);
    //  } else {
      const data = { username:name, surname:surname, email_address:email, phone_number:phone,products:products, prices:prices,address:address,fileType:fileType};
      try {
        const response = await axios.post(
          'https://us-central1-solar-pv-sales.cloudfunctions.net/GEHCO-Hubspot',
          data,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        // console.log('Response:', response.data,response);
        if (response.status===200) {
          const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                 return_url: `${window.location.origin}/completion`,
               
              
      
            }
            
          });
          let conformation = false
          if(error){
            setMessage(error.message);
            conformation = true
          }
          if(conformation===false){
            const response2 = await axios.post(
              'https://gehco-email-sender-194a36131529.herokuapp.com/order',
              data,
              {
                headers: {
                  'Content-Type': 'application/json',
                },
              }
            );
            // console.log('Response:', response2.data,response);
  
            setMessage("Payment successful!");
          }
          
         
          
        } else {
          setMessage("An error occurred while processing your payment.");
        }
      } catch (error) {
        console.error(error);
      }
      
    
    
    setIsProcessing(false);
  };



  return (
    <form
      id="payment-form"
      onSubmit={handleSubmit}
      className="p-3"
      style={{ backgroundColor: "#BCC6CC", borderRadius: "20px" }}
    >
     <div style={{ paddingBottom:'10px' }}>{`25% deposit which is £${props.Total25} and 75% after sending the documentation `}</div>
      <PaymentElement className="black-payment-element" />
      <button
        style={{ backgroundColor: "#E4F385", color: "black" }}
        className="btn rounded-pill shadow mt-3 mb-3"
        disabled={isProcessing || !stripe || !elements}
        id="submit"
       
      >
        <span id="button-text">
          {isProcessing ? "Processing ..." : "Pay now"}
        </span>
      </button>
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}
